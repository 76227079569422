import ContactUsLayout1 from "./contactus/ContactUsLayout1";
import {ComponentProps} from "../../../util/CommonDto";
import AboutMeBsc from "./aboutme/AboutMeBsc";
import ContactInfoBsc from "./contactinfo/ContactInfoBsc";
import ProductBsc from "./product/ProductBsc";
import FooterLayout2 from "./footer/FooterLayout2";
import FooterLayout3 from "./footer/FooterLayout3";
import Footer from "./footer/Footer";
import SubscribeNow from "./SubscribeNow/SubscribeNow";
import HeaderLayout1 from "./header/HeaderLayout1";
import HeaderLayout2 from "./header/HeaderLayout2";
import BannerLayout1 from "./banner/BannerLayout1";
import BannerLayout2 from "./banner/BannerLayout2";
import BannerLayout3 from "./banner/BannerLayout3";
import BannerLayout4 from "./banner/BannerLayout4";
import AboutUs from "./aboutUs/AboutUs";
import ContactUsLayout2 from "./contactus/ContactUsLayout2";
import GalleryLayout1 from "./gallery/GalleryLayout1";
import GalleryLayout2 from "./gallery/GalleryLayout2";
import ComingSoon from "./comingSoon/ComingSoon";
import OurServicesLayout1 from "./ourServices/ourServicesLayout1";
import SectionHeader from "./sectionheader/SectionHeader";
import Products from "./products/Products";
import OurServicesLayout2 from "./ourServices/ourServicesLayout2";
import HeaderLayout3 from "./header/HeaderLayout3";
import AlertBox from "../../../components/alertBox/AlertBox";
import GalleryLayout3 from "./gallery/GalleryLayout3";
import {FixedGallery} from "./gallery/FixedGallery";
import Container from "../../../components/container/Container";
import OurTeam from "./ourteam/OurTeam";
import ProductFeature from "./productfeature/ProductFeature";
import {JobsLayout1} from "./Jobs/jobsLayout"
import {NoSuchWidget} from "./emptyWidget/NoSuchWidget";
import {JobWidget} from "./Jobs/JobWIdget";
import OurServicesLayout3 from "./ourServices/OurServicesLayout3";
import {BlogWidget} from "./Blog/BlogWidget";
import {BlogDetail} from "./Blog/BlogDetail";
import FlexibleContainer from "./flexibleContainer/FlexibleContainer";
import {TestimonialWidget} from "./testimonial/TestimonialWidget";
import ContactUsLayout3 from "./contactus/ContactUsLayout3";
import CounterLayout1 from "./counter/CounterLayout1";
import ContactUsLayout4 from "./contactus/ContactUsLayout4";
import LinearPercentile from "./percentile/linear/LinearPercentile";
import SectionHeader2 from "./sectionheader/SectionHeader2/SectionHeader2";
import Whatsapp from "./whatsapp/Whatsapp";
import {LocateUs} from "./locateUs/LocateUs";
import CallUs from "./callUs/CallUs";
import AppPortfolio from "./portfolio/AppPortfolio";
import HeaderRibbon from "./headerRibbon/HeaderRibbon";
import ApplicationForm from "./applicationForm/ApplicationForm";

export class WidgetRenderer {
    static render(widget: any, props: ComponentProps<any, any>, args: any): JSX.Element {
        switch (widget['type']) {
            case 'AboutMe' :
                return <AboutMeBsc {...props} content={widget['content']}/>;
            case 'ContactInfo':
                return <ContactInfoBsc {...props} data={widget}/>;
            case 'Products':
                return <ProductBsc {...props} product={widget}/>;
            default:
                return <div>No Widget Added</div>;
        }
    }

    static renderV2(siteId: string, breadCrumbs: any, widget: any, props: ComponentProps<any, any>, args: any, extraData?: any, widgetposition?: any, shadeClass?: any): JSX.Element {
        console.log('uiTemplate', widget['widget']['uiTemplate']);
        console.log('#68', widget['widget'])
        if (widget['valid']) {

            console.log('#70', widget['widget']);
            switch (widget['widget']['uiTemplate']) {
                case 'header_ribbon' :
                    return <HeaderRibbon {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                         data={widget['config']} key={widget.id} config={widget['config']}/>;
                case 'header':
                    return <HeaderLayout1 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                          config={widget['widget']} data={widget['config']} channelId={siteId}
                                          key={widget.id}/>;
                case 'header_l2':
                    return <HeaderLayout2 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                          config={widget['widget']} data={widget['config']} channelId={siteId}
                                          key={widget.id}/>;
                case 'header_l3':
                    return <HeaderLayout3 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                          config={widget['widget']} data={widget['config']} channelId={siteId}
                                          key={widget.id}/>;
                case 'section_header':
                    return <SectionHeader2 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                           config={widget['widget']} data={widget['config']} siteId={siteId}
                                           key={widget.id}/>;
                case 'hero_banner_l1':
                    return <BannerLayout1 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                          config={widget['widget']} data={widget['config']} siteId={siteId}
                                          key={widget.id}/>;
                case 'hero_banner_l2':
                    return <BannerLayout2 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                          config={widget['widget']} data={widget['config']} siteId={siteId}
                                          key={widget.id}/>;
                case 'hero_banner_l3':
                    return <BannerLayout3 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                          config={widget['widget']} data={widget['config']} siteId={siteId}
                                          key={widget.id}/>;
                case 'hero_banner_l4':
                    return <BannerLayout4 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                          config={widget['widget']} data={widget['config']} siteId={siteId}
                                          key={widget.id}/>;
                case 'about_us':
                    return <AboutUs {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                    config={widget['widget']} data={widget['config']} siteId={siteId} key={widget.id}
                                    shadeClass={shadeClass}/>;
                case 'app_portfolio_l1':
                    return <AppPortfolio {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                         config={widget['widget']} data={widget['config']} siteId={siteId}
                                         key={widget.id}
                    />;
                case 'team':
                    return <OurTeam {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                    config={widget['widget']} data={widget['config']} siteId={siteId} key={widget.id}/>;
                case 'our_services':
                case 'our_services_l1':
                    return <OurServicesLayout1 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                               config={widget['widget']} data={widget['config']} siteId={siteId}
                                               key={widget.id}/>;
                case 'our_services_l2':
                    return <OurServicesLayout2 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                               config={widget['widget']} data={widget['config']} siteId={siteId}
                                               key={widget.id}/>;
                case 'our_services_l3':
                    return <OurServicesLayout3 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                               config={widget['widget']} data={widget['config']} siteId={siteId}
                                               key={widget.id}/>;
                case 'coming_soon':
                    return <ComingSoon {...props} htmlId={widget['htmlId']} config={widget['widget']}
                                       data={widget['config']} siteId={siteId} key={widget.id}/>;
                case 'product_feature_left':
                case 'product_feature_right':
                case 'product_feature':
                    return <ProductFeature {...props} htmlId={widget['htmlId']} shadeClass={shadeClass}
                                           themeClass={widgetposition} config={widget['widget']} data={widget['config']}
                                           siteId={siteId} key={widget.id}/>;
                case 'gallery_l1':
                    return <GalleryLayout1 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                           config={widget['widget']} data={widget['config']} channelId={siteId}
                                           key={widget.id}/>;
                case 'gallery_l2':
                    return <GalleryLayout2 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                           config={widget['widget']} data={widget['config']} channelId={siteId}
                                           key={widget.id}/>;
                case 'gallery_l3':
                    return <GalleryLayout3 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                           config={widget['widget']} data={widget['config']} channelId={siteId}/>;
                case 'products_l1':
                    return <Products {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                     config={widget['widget']} data={widget['config']} siteId={siteId}
                                     key={widget.id}/>;
                case 'nmc_registration_form':
                    return <ApplicationForm  {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                             data={widget['config']} key={widget.id} config={widget['config']}/>
                case 'contact_us_l1':
                    return <ContactUsLayout1 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                             config={widget['widget']} data={widget['config']} siteId={siteId}
                                             key={widget.id}/>;
                case 'contact_us_l2':
                    return <ContactUsLayout2 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                             config={widget['widget']} data={widget['config']} siteId={siteId}
                                             key={widget.id}/>;
                case 'contact_us_l3':
                    return <ContactUsLayout3 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                             config={widget['widget']} data={widget['config']} siteId={siteId}
                                             key={widget.id}/>;
                case 'contact_us_l4':
                    return <ContactUsLayout4 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                             config={widget['widget']} data={widget['config']} siteId={siteId}
                                             key={widget.id}/>;
                case 'subscribe_now':
                    return <SubscribeNow {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                         config={widget['widget']} data={widget['config']} siteId={siteId}
                                         key={widget.id}/>;
                case 'container_l1':
                    return <Container {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                      config={widget['widget']} data={widget['config']} breadCrumb={breadCrumbs}
                                      siteId={siteId} key={widget.id}/>;
                case 'counter_l1':
                    return <CounterLayout1 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                           config={widget['widget']} data={widget['config']} siteId={siteId}
                                           key={widget.id}/>;
                case 'Percentile_l1':
                    return <LinearPercentile {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                             config={widget['widget']} data={widget['config']} siteId={siteId}
                                             key={widget.id}/>;
                case 'footer':
                case 'footer_l1':
                    return <Footer {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                   config={widget['widget']} data={widget['config']} channelId={siteId}
                                   key={widget.id}/>;
                case 'footer_l2':
                    return <FooterLayout2 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                          config={widget['widget']} data={widget['config']} channelId={siteId}
                                          key={widget.id}/>;
                case 'footer_l3':
                    return <FooterLayout3 {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                          config={widget['widget']} data={widget['config']} channelId={siteId}
                                          key={widget.id}/>;
                case 'jobs_l1':
                    return <JobsLayout1 {...props} htmlId={widget['htmlId']} config={widget['widget']}
                                        data={widget['config']} siteId={siteId} key={widget.id}/>;
                case 'blogs_l1':
                    return <BlogWidget {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                       config={widget['widget']} data={widget['config']} key={widget.id}/>;
                case 'job_detail_l1':
                    return <JobWidget {...props} htmlId={widget['htmlId']} config={extraData} key={widget.id}
                                      themeClass={widgetposition}/>;
                case 'empty_page_error_l1':
                    return <NoSuchWidget/>
                case 'blog_detail_l1':
                    return <BlogDetail {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                       config={extraData} key={widget.id}/>
                case 'flex_l1':
                    return <FlexibleContainer {...props} htmlId={widget['htmlId']} themeClass={widgetposition}
                                              config={extraData} key={widget.id} data={widget['config']}
                                              shadeClass={shadeClass}/>
                case 'testimonials_l1':
                    return <TestimonialWidget {...props} htmlId={widget['htmlId']} shadeClass={shadeClass}
                                              themeClass={widgetposition} config={extraData} key={widget.id}
                                              data={widget['config']}/>
                case 'fixed_gallery_l1':
                    return <FixedGallery {...props} htmlId={widget['htmlId']} shadeClass={shadeClass}
                                         themeClass={widgetposition} config={extraData} key={widget.id}
                                         data={widget['config']}/>
                case 'locate_us':
                    return <LocateUs   {...props} htmlId={widget['htmlId']} shadeClass={shadeClass}
                                       themeClass={widgetposition} config={extraData} key={widget.id}
                                       data={widget['config']}/>
                case 'call_us_l1':
                    return <CallUs   {...props} htmlId={widget['htmlId']}
                                     themeClass={widgetposition} config={extraData} key={widget.id}
                                     data={widget['config']}/>
                case 'whats_app_l1':
                    return <Whatsapp {...props} htmlId={widget['htmlId']} config={extraData} key={widget.id}
                                     data={widget['config']}/>
                default:
                    return <div>No Widget Added</div>;
            }
        } else {
            return <AlertBox {...props} htmlId={widget['htmlId']} config={widget['widget']} data={widget['widget']}/>;
        }
    }
}
