import React, {Component} from "react";
import {FlexibleContainerProps} from "../../../../models/BannerModel";
import {MediaSelector} from "../MediaSelector/MediaSelector";
import './FlexibleContainer.scss';
import HeadingTitles from "../common/sectionHeading/HeadingTitles";

class FlexibleContainer extends Component<FlexibleContainerProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            numberOfSection: this.props?.data?.htmlSlices?.length ?? 0,
            layout: this.props?.data?.layout?.split("-") ?? [],
            showTitle: this.props?.data?.showTitle ?? false,
        };
    }

    render() {
        return (
            <section id={this.props.htmlId} style={{
                backgroundImage: `url(${this.props?.data?.bgImage?.url})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }} className={`${this.props.themeClass} ${this.props.shadeClass} site_widget position-relative`}>
                <div className="container">
                    {this.state.showTitle ?
                        <div className="row">
                            <HeadingTitles isTitleSet={this.state.showTitle} heading={this.props?.data['title']} classesObject={{headingClass:'heading-style', descriptionClass: 'sub-title'}} headingTag="h2"  description={this.props?.data['description']} alignment="center" layout="layout_1"/>
                      </div> : <div></div>}
                    <div className="row">
                        {this.props?.data?.htmlSlices?.map((Data: any, index: number) => {
                            if (this.state.layout.length === 1) {
                                return <div className={`col-md col-sm-12`}>
                                    <MediaSelector data={Data}/>
                                </div>
                            } else {
                                return <div className={`col-md-${this?.state?.layout[index]}`}>
                                    <MediaSelector data={Data}/>
                                </div>
                            }
                        })}
                    </div>
                </div>
            </section>
        )
    }
}

export default FlexibleContainer;
