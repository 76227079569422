import React, {Component} from "react";
import "./OurServicesLayout3.scss";
import ScrollAnimation from "react-animate-on-scroll";
import {OurServicesProps} from "../../../../models/BannerModel";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import "material-icons/iconfont/filled.css";

class OurServicesLayout3 extends Component<OurServicesProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    getNumberOfDivision = () => {
        let numberOfColumn: number = this.props?.data?.columns;
        if (numberOfColumn === 4) {
            return 3;
        } else if (numberOfColumn === 3) {
            return 4;
        } else {

            return 2;
        }
    }

    renderIconType = (type: string, icon: string) => {
        switch (type) {
            case "Material":
                return "material-icons";
            case "Bootstrap":
                return `bi bi-${icon}`;
            default:
                return "";
        }
    }


    render() {
        return (
            <section id={this.props.htmlId}
                     className={`${this.props.themeClass} ourServices_l3 testingFontFamily ptb_80 position-relative`}>
                <div className="container">
                    <div className="plus rotation"></div>
                    <ScrollAnimation animateIn='fadeInDown'>
                        <div className="section-header text-center mb-3">
                            <HeadingTitles heading={this.props.data['title']} classesObject={{
                                headingClass: 'heading-style',
                                descriptionClass: 'sub-title'
                            }} headingTag="h2" layout="layout_1" description={this.props.data['subtitle']}
                                           alignment="center"/>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp'>
                        <div className="row justify-content-center">
                            {this.props.data.services?.map((service: any) =>
                                <div key={service.id} className={`col-md-${this.getNumberOfDivision()} col-sm-6 mb-3 mt-4`}>
                                    <div className="card single_service_box">
                                        <div className={'card-body'}>
                                            <div className="service_icon theme_icons">

                                                {service?.icon?.type === 'Image' ?
                                                    <img src={service.icon?.image?.url ?? ''}
                                                         style={{height: '17vh', objectFit: 'cover'}}/> :
                                                    <div className={'s_icons'}>
                                                        <i
                                                            className={this.renderIconType(service.icon?.type ?? "", service.icon?.className ?? "")}>{service.icon?.type === "Bootstrap" ? null : service.icon?.className}</i>
                                                    </div>
                                                }

                                            </div>
                                           <div className={'s_content mt-3'}>
                                               <h3 className="heading-style">{service.title}</h3>
                                               <p className="sub-title">{service.description}</p>
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </ScrollAnimation>
                </div>
            </section>
        );
    }
}

export default OurServicesLayout3;
