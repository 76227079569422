import React, {Component} from "react";
import {BannerProps} from "../../../../models/BannerModel";
import './BannerLayout1.scss';
import {Carousel} from "react-bootstrap";

class BannerLayout1 extends Component<BannerProps, any> {
    slidesData = [];

    constructor(props: any) {
        super(props);

        this.setItems();
        this.state = {
            name: '',
            description: '',
        };
    }

    setItems = () => {
        let slides : any = [];
        this.props.data?.slides.map((slide : any)=>{
            if(slide.active){
                slides.push(slide);
            }
        });
        this.slidesData = slides;
    }

    render() {
        return <section id={this.props.htmlId} className={`${this.props.themeClass} banner-sec_l1 ptb_80 hero-1 testingFontFamily`}>
                <div className="container">
                    <Carousel indicators={false} controls={false} >
                        {this.slidesData?.map((slide: any, index: any) =>
                            <Carousel.Item key={index} interval={(this.slidesData?.length === 1) ? null : this.props.data?.slideDelay}>
                                <div className="row align-items-center">
                                    <div className="col-md-5">
                                        <div className="d-flex justify-content-center">
                                            <img className="location-img img-fluid animate fadeInDown three" src={slide['url']} loading="lazy"/>
                                        </div>
                                    </div>
                                    <div className="col-md-7 d-flex justify-content-end">
                                        <div className="content-wrapper">
                                            <h1 className="animate fadeInUp three heading-style">{slide['title']}</h1>
                                            <p className="animate fadeInUp three sub-title">{slide['description']}</p>
                                            <button className="button button_theme animate fadeInUp three">Explore more</button>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        )}
                    </Carousel>
                </div>
            {/*<div className="one-row_bottom_divider" style={{*/}
            {/*    height:145,*/}
            {/*}}>*/}
            {/*    <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" width="100%" height="145" viewBox="0 0 1920 182"*/}
            {/*         preserveAspectRatio="none">*/}
            {/*        <path*/}
            {/*            d="M1921.91,916.348c0.33,39.216-.34,79.431,0,118.642Q957.95,1035.5-6,1035V853c40.431,10.8,81,19.794,122.5,27.149,62.957,11.157,117.371,15.375,180.742,21.116,79.864,7.236,165.843,26.989,255.045,42.232,109.142,18.65,243.949,40.091,308.265,44.243,137.637,8.886,313.056-2.783,504.066-36.2,127.4-22.286,223.4-43.261,354.45-45.248A1569.414,1569.414,0,0,1,1921.91,916.348Z"*/}
            {/*            transform="translate(0 -853)"></path>*/}
            {/*    </svg>*/}
            {/*</div>*/}

        </section>;
    }
}

export default BannerLayout1;
